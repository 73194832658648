$width: 15px;
$height: 15px;
$bounce_height: 30px;

@keyframes bounce {
  0% {
    top: $bounce_height;
    height: 5px;
    border-radius: 60px 60px 20px 20px;
    transform: scaleX(2);
  }
  35% {
    height: $height;
    border-radius: 50%;
    transform: scaleX(1);
  }
  100% {
    top: 0;
  }
}

.loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: rgba(0,0,0,0.15);
  z-index: 99;
  color: #fff;
  .loader-bounceball {
    position: relative;
    display: inline-block;
    height: 37px;
    width: $width;
    &:before {
      position: absolute;
      top: 0;
      content: "";
      display: block;
      width: $width;
      height: $height;
      border-radius: 50%;
      background-color: $primary-color;
      transform-origin: 50%;
      animation: bounce 500ms alternate infinite ease;
      // box-shadow: 0 0 1px 1px rgba(0,0,0,0.5);
    }
  }
  .loader-text {
    color: $primary-color;
    display: inline-block;
    margin-top: 0.5em;
    margin-left: 14px;
    font-weight: 500;
    // text-shadow: 0 0 1px 1px rgba(0,0,0,0.5);
  }
}
