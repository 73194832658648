.dashboard-layout{
    display: grid;
    grid-template-columns: 1fr; /* Side nav is hidden on mobile */
    grid-template-rows: 90px 1fr;
    grid-template-areas:
      'header'
      'main'
      'footer';
    min-height: 100vh;
    .sidebar{
        display: none;
    }
    @media(max-width: 767px){
        padding-top: 90px;
        display: block;
    }
    @media(min-width: 768px){
        grid-template-columns: 240px 1fr;
        grid-template-rows: 90px 1fr;
        grid-template-areas:
            "header header"
            "sidenav main";
        .site-header{
            grid-area: header;
        }
        .sidebar{
            grid-area: sidenav;
            display: flex;
            flex-direction: column;
        }
        .main-content{
            grid-area: main;
            overflow-x: hidden;
            overflow-y: visible;
        }
    }
}