input[type="text"], input[type="search"], input[type="number"], 
input[type="email"], input[type="password"], input[type="file"],
textarea, select, .custom-select{
    display: block;
    width: 100%;
    padding: 5px 0;
    @include fontSize(16px);
    line-height: 1.5;
    color: $font-color;
    background-color: rgba($color: #000000, $alpha: 0);
    border: 0 none;
    border-bottom: 1px solid $greyeee;
    border-radius: $input-border-radius;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    outline: none;
}
input[type="text"]:focus, input[type="search"]:focus, input[type="number"]:focus, 
input[type="email"]:focus, input[type="password"]:focus, input[type="file"]:focus,
textarea:focus, select:focus, .custom-select:focus,
.form-control:focus{
    box-shadow: none !important;
    border-color: $primary-color;
    background-color: transparent;
}
.btn + .btn{
    margin-left: 15px;
}
button:focus{
    outline: none;
}
.form-group{
    margin-bottom: 1.25rem;
    > label{
        @include fontSize(16px);
        color: $primary-color;
    }
    .inline-controls{
        display: flex;
        flex-wrap: wrap;
    }
    .fight-dates-value{
        .inline-controls{
            margin-bottom: 5px;
            .custom-fight-date{
                margin-left: auto;
                .form-control{
                    padding-top: 0;
                    padding-bottom: 0;
                    @include fontSize(14px);
                    line-height: 1.42;
                }
            }
        }
    }
    .custom-control-label{
        &::before{
            background: $white;
            border: 1px solid $grey4b;
        }
    }
    .custom-radio .custom-control-input{
        &:checked ~ .custom-control-label::before {
            color: $primary-color;
            @include gradient-bg($primary-color);
            @include box-shadow($primary-color);
            border-color: $primary-color;
          }
          &:focus ~ .custom-control-label::before {
            box-shadow: none;
          }
          &:active ~ .custom-control-label::before {
            color: $primary-color;
            background-color: $primary-color;
            @include box-shadow(none);
          }
        &:checked ~ .custom-control-label{
            &::after{
                content: none;
            }
        }
    }
    .custom-checkbox .custom-control-input{
        ~ .custom-control-label::before {
            border-radius: 2px;
        }
        &:checked ~ .custom-control-label::before {
            color: $primary-color;
            @include gradient-bg($primary-color);
            @include box-shadow($primary-color);
            border-color: $primary-color;
        }
        &:focus ~ .custom-control-label::before {
            box-shadow: none;
        }
        &:active ~ .custom-control-label::before {
            color: $primary-color;
            background-color: $primary-color;
            @include box-shadow(none);
        }
    }
    .tru-toggle-button{
        padding-left: 3.5rem;
        .custom-control-input{
            ~ .custom-control-label{
                line-height: 1.75rem;
            }
            ~ .custom-control-label::before {
                border-radius: 1rem;
                width: 3rem;
                height: 1.75rem;
                background: $grey4b;
                border-color: $grey4b;
                top: 0;
            }
            ~ .custom-control-label::after {
                border-radius: 1rem;
                width: 1.25rem;
                height: 1.25rem;
                content: " ";
                color: $white;
                background: $white;
                top: 0.25rem;
                left: 0.25rem;
                transition: left 0.5s ease;
            }
            &:checked ~ .custom-control-label::before,
            &:active ~ .custom-control-label::before {
                color: $primary-color;
                background: $primary-color;
                @include box-shadow(none);
                border-color: $primary-color;
            }
            &:focus ~ .custom-control-label::before {
                box-shadow: none;
            }
            &:checked ~ .custom-control-label::after,
            &:active ~ .custom-control-label::after {
                left: 1.45rem;
            }
        }
    }
}
.property-section{
    background: rgba($color: $white, $alpha: 0.5);
    outline: 1px solid rgba($color: $primary-color, $alpha: 0.2);
    padding: 15px 0;
    margin: 0;
    label{
        margin-bottom: 0.25rem;
        @include fontSize(15px);
        color: $grey4b;
        font-weight: 500;
    }
    .form-group{
        margin-bottom: 0;
    }
}
.type-objects-container{
    margin: 0;
    .objects-container-section{
        background: rgba($color: $white, $alpha: 0.5);
        padding: 15px;
       
        & + .objects-container-section{
            margin-top: 15px;
        }
    }
    .addnew-objects-container{
        background: rgba($color: $primary-color, $alpha: 0.1);
        padding: 15px;
        border-radius: 15px;
        padding: 1.5rem;
        & + .addnew-objects-container{
            margin-top: 15px;
     
        }
        .container-form{
            margin-top: 25px;
            .container-objects-elements{
                background: rgba($color: $white, $alpha: 0.5);
                padding: 15px;
                margin-bottom: 15px;
            }
        }
    }
    h3{
        @include fontSize(16px);
        color: $primary-color;
    }
}
.add-image-div{
    position: relative;
    input[type=file]{
        visibility: hidden;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
    }
    label{
        border: 1px solid $greyeee;
        border-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        color: $grey4b;
        width: 100px;
        height: 100px;
        @include fontSize(13px);
        padding: 5px;
        .plus-icon{
            font-weight: 600;
            @include fontSize(32px);
            line-height: 1;
            margin-bottom: 5px;
        }
    }
}
.image-container{
    margin-top: 15px;
    max-height: 220px;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 10px;
    background-color: rgba($color: $white, $alpha: 0.5);
    outline: 1px solid rgba($color: $primary-color, $alpha: 0.2);
    h4{
        @include fontSize(15px);
        color: $grey4b;
        margin: 0 0 10px;
        width: 100%;
    }
    img{
        object-fit: contain;
        border: 2px solid $greyeee;
        margin-bottom: 10px;
        margin-right: 1%;
        transition: all 0.3s ease;
        background: $white;
        width: 15.5%;
        min-width: 100px;
        &.selected, &:hover, &:focus{
            border-color: $primary-color;
            outline: 4px solid rgba($color: $primary-color, $alpha: 0.3);
        }
    }
}
.color-box{
    margin-bottom: 15px;
    padding-top: 5px;
}
.object-list{
    background: rgba($color: $white, $alpha: 0.5);
    outline: 1px solid rgba($color: $primary-color, $alpha: 0.2);
    padding: 10px 15px;
    border-radius: 8px;
    margin: 0 0 5px;
    display: flex;
    align-items: center;
    .object-list-label{
        flex: 1;
    }
    .object-icon{
        width: 20px;
        height: 20px;
        color: $primary-color;
        margin-right: 10px;
        display: inline-block;
        vertical-align: middle;
        svg{
            width: 20px;
            height: 20px;
            fill: $primary-color;
        }
    }
    label{
        margin-bottom: 0;
        @include fontSize(15px);
        color: $grey4b;
        font-weight: 500;
    }
}
.selected-objects{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0;
    li{
        display: inline-block;
        margin-right: 5px;
        margin-bottom: 5px;
        list-style: none;
        white-space: nowrap;
        text-transform: capitalize;
        background: rgba($color: $white, $alpha: 0.5);
        outline: 1px solid rgba($color: $primary-color, $alpha: 0.2);
        padding: 5px 10px;
        .btn{
            margin-left: 5px;
            background: transparent;
            border: 0 none;
            margin-top: -5px;
        }
    }
}