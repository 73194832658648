.react-confirm-alert-overlay{
    background: rgba($color: #000000, $alpha: 0.5);
    z-index: 9999999;
    .react-confirm-alert-body{
        box-shadow: 0 0 12px rgba(0, 0, 0, .5);
        background-color: #f6f7fb;
        background-clip: padding-box;
        border-radius: 0;
        text-align: center;
        h1{
            font-weight: 400;
            @include fontSize(26px);
            color: $font-color;
            margin-bottom: 25px;
        }
        .react-confirm-alert-button-group{
            justify-content: center;
            button{
                display: inline-block;
                font-weight: 500;
                text-align: center;
                vertical-align: middle;
                -webkit-user-select: none;
                -ms-user-select: none;
                user-select: none;
                padding: .5rem 1.25rem;
                font-size: 1rem;
                line-height: 1.5;
                border-radius: 32px;
                transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out,box-shadow .15s ease-in-out;
                cursor: pointer;
                color: $white;
                background-color: $theme-primary-color;
                border: 1px solid $theme-primary-color;
                &:hover, &:focus{
                    box-shadow: 0 0 0 6px rgba($color: $theme-primary-color, $alpha: 0.1);
                    opacity: 0.95;
                }
            }
        }
    }
}