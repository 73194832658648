.sidebar{
    box-shadow: 7px 0 14px rgba(0,37,61,0.10);
    background: $white;
    padding: 20px;
    position: fixed;
    top: 90px;
    left: 0;
    bottom: 0;
    width: 240px;
    .sidemenu-link{
        a{
            color: #4b4b4b;
            @include fontSize(20px);
            letter-spacing: 1px;
            font-weight: 500;
            &:hover{
                text-decoration: underline;
            }
        }
    }
}