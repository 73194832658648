.toggle-content-title{
    display: flex;
    cursor: pointer;
    color: $grey4b;
    margin-bottom: 0 !important;
    & + .form-group{
        margin-top: 25px;
    }
    .arrow{
        align-self: flex-end;
        margin-left: auto;
        svg{
            width: 12px;
            height: 12px;
            color: $grey4b;
        }
    }
}