header{
    background: $theme-primary-color;
    padding: 30px 20px;
    box-shadow: 3px 0 14px rgba(0,37,61,0.14);
    &.site-header{
        display: flex;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        height: 90px;
        @include align-items(center);
        z-index: 9;
    }
    h1{
        color: $white;
        margin: 0;
        @include fontSize(26px);
        flex: 1;
        text-align: center;
    }
    &.loginout-header{
        h1{
            text-align: left;
        }
    }
    .btn-logout{
        outline: none;
        color: $white;
        padding: 0;
    }
}