.modal-open {
    overflow: hidden;
}
  // Container that the modal scrolls within
  .modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999999;
    display: none;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    outline: 0;
  }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
  }
  .modal-dialog {
    position: relative;
    width: auto;
    margin: 10px auto;
    max-width: 320px;
    @media(min-width: 576px){
      max-width: 500px;
      margin: 1.75rem auto;
    }
    @media(min-width: 768px){
      max-width: 750px;
    }
  }
  
  // Actual modal
  .modal-content {
    position: relative;
    box-shadow: 0 0 12px rgba(0, 0, 0, .5);
    outline: 0;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #f6f7fb;
    background-clip: padding-box;
  }
  
  // Modal background
  .modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999999;
    background-color: #000;
    &.fade { opacity: 0; }
    &.show { opacity: 0.5; }
  }
  
  // Modal header
  .modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1rem;
    background: $theme-primary-color;
    color: $white;
    text-align: center;
    // Title text within header
    .modal-title {
      margin: 0;
      line-height: 2;
      flex: 1;
      font-size: 21px;
    }
    // Close icon
    .close {
      margin-top: -2px;
      background: transparent;
      border: 0 none;
      font-size: 32px;
      color: $white;
      padding: 0;
      line-height: 1;
      .sr-only{
        position: absolute;
        opacity: 0;
        visibility: hidden;
        right: 0;
        top: 0;
      }
    }

  }
  // Modal body
  .modal-body {
    position: relative;
    padding: 20px 15px;
    /* TYPOGRAPHY */
    h3{
      font-weight: 400;
      @include fontSize(21px);
      color: $primary-color;
      margin-bottom: 25px;
    }
  }
  
  // Footer (for actions)
  .modal-footer {
    padding: 0 15px 20px;
    text-align: center;
    .btn + .btn {
      margin-bottom: 0;
      margin-left: 5px;
    }
    // .btn-group .btn + .btn {
    //   margin-left: 15px;
    // }
    .btn-block + .btn-block {
      margin-left: 0;
    }
    .btn{
      margin: .25rem;
    }
  }
  
  .modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll;
  }