.page{
  box-shadow: 0 0 35px rgba(53,152,219,0.10);
  background: $white;
  margin: 20px;
  padding: 20px;
  min-height: 60vh;
  h1 {
    margin-bottom: 0;
  }
  .emoji {
    font-size: 4em;
  }
  .page-breadcrumb{
    .breadcrumb{
      padding: 0;
      background: transparent;
      margin-bottom: 0.25rem;
      .breadcrumb-item{
        color: $primary-color;
        @include fontSize(24px);
        & + .breadcrumb-item::before{
          color: $primary-color;
        }
        @media(max-width: 767px){
          @include fontSize(21px);
        }
      }
      a {
        color: $primary-color;
      }
    }
  }
  // .page .page-breadcrumb .breadcrumb .breadcrumb-item
  .page-header{
    margin-bottom: 1.5em;
    align-items: center;
    button{
      margin-right: 15px;
    }
  }
  h3{
    font-weight: 400;
    @include fontSize(26px);
    color: $primary-color;
    @media(max-width: 767px){
      @include fontSize(21px);
    }
  }
  h4{
    color: $dark-color;
    font-weight: 500;
    @include fontSize(30px);
    margin-bottom: 35px;
    @media(max-width: 767px){
      @include fontSize(26px);
    }
  }
  .responsive-table{
    max-width: 100%;
    overflow-x: auto;
  }
  table{
    width: 100%;
    border: 1px solid rgba(132,146,175,0.3);
    th, td{
      padding: 10px 15px;
      border-color: rgba(132,146,175,0.3);
      color: $font-color;
      white-space: normal;
      overflow-wrap: break-word;
      max-width: 280px;
      @media(max-width: 767px){
        max-width: 220px;
      }
    }
    th{
      @include fontSize(21px);
      font-weight: 500;
      background: $grey-bg;
      @media(max-width: 767px){
        @include fontSize(18px);
      }
    }
    td{
      @include fontSize(17px);
      @media(max-width: 767px){
        @include fontSize(15px);
      }
    }
    .action-col{
      width: 200px;
    }
  }
  .action-btns-group{
    display: flex;
    align-items: center;
    .action-btn{
      background: transparent;
      border: 0 none;
      padding: 0;
      display: inline-block;
      color: $primary-color;
      cursor: pointer;
      svg{
        width:25px;
        fill: $primary-color;
      }
      & + .action-btn{
        margin-left: 15px;
      }
    }
  }
}
