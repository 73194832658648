.listing-container{
    border: 1px solid rgba($color: $primary-color, $alpha: 0.3);
    margin: 20px 0;
    @media(min-width: 768px){
      max-width: 65%;
    }
    .list-title{
        color: $font-color;
        @include fontSize(24px);
        line-height: 1.5;
        letter-spacing: 1px;
        font-weight: 500;
        display: block;
        padding: 15px 18px;
        border-bottom: 1px solid $off-white;
        margin-bottom: 0;
    }
    ul{
        margin: 0;
    }
    li{
      list-style: none;
      display: block;
      padding: 12px 18px;
      color: $font-color;
      @include fontSize(17px);
      letter-spacing: 1px;
      font-weight: 400;
      text-transform: capitalize;
      cursor: pointer;
      a{
        color: $font-color;
        &:hover{
            color: $dark-color;
        }
      }
      & + li{
        border-top: 1px solid $off-white;
      }
      &:hover{
          background: $off-white;
          a{
              color: $dark-color;
          }
        }
    }
    #xpsAccounts{
        li{
            font-weight: 500;
        }
    }
    .list-footer{
        border-top: 1px solid $off-white;
        .footer-title{
            padding: 15px 30px;
            color: rgba($color: $font-color, $alpha: 0.5);
            @include fontSize(17px);
            line-height: 1.5;
            letter-spacing: 1px;
            font-weight: 500;
            display: block;
            margin-bottom: 0;
            cursor: pointer;
            &:hover{
                color: $font-color;
            }
            & + ul{
                border-top: 1px solid $off-white;
            }
            .arrow{
                float: right;
                width: 14px;
            }
        }
        li{
            padding-left: 30px;
            padding-right: 30px;
        }
    }
    .compaign-status{
        display: flex;
        .status-dot{
            width: 35px;
            height: 35px;
            border-radius: 50%;
            background: $primary-color;
            margin-right: 10px;
        }
        .status-details{
            flex: 1;
            span{
                display: block;
                text-transform: capitalize;
            }
        }
    }
  
} 
 .cursor-pointer{
    cursor: pointer;
}
.info-icon{
    margin-left: 10px;
}