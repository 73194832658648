.btn {
    display: inline-block;
    font-weight: 500;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: .5rem 1.25rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 32px;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    cursor: pointer;
    &.btn-sm{
        padding: .35rem 1rem;
        font-size: 0.85rem;
        line-height: 1.42;
    }
    &:disabled{
        opacity: 0.5;
        cursor: none;
        pointer-events: none;
    }
    &.btn-primary {
        color: $white;
        background-color: $theme-primary-color;
        border-color: $theme-primary-color;
        &:hover, &:focus{
            box-shadow: 0 0 0 6px rgba($color: $theme-primary-color, $alpha: 0.1);
            opacity: 0.95;
        }
    }
    &.btn-default {
        color: $theme-primary-color;
        background-color: #f2f2f2;
        border-color: #f2f2f2;
        &:hover, &:focus{
            box-shadow: 0 0 0 6px rgba($color: #f2f2f2, $alpha: 0.1);
            opacity: 0.95;
        }
    }
    &.btn-switch{
        margin: 5px auto;
        display: block;
        @include fontSize(14px);
    }
    &.btn-delete{
        color: $red !important;
        line-height: 1;
        padding: 3px;
        svg{
            fill: $red !important;
            width: 12px;
            height: 12px;
        }
    }
    &.obj-action-btn{
        background: rgba(0,0,0,0);
        color: $primary-color;
        border: 2px solid $greyeee;
        border-radius: 0;
        padding: 7px;
        line-height: 1;
        svg{
            width: 12px;
            height: 12px;
            fill: $primary-color;
        }
        &:hover, &:focus{
            box-shadow: 0 0 0 3px rgba($color: $grey4b, $alpha: 0.1);
            opacity: 0.95;
            background: $greyeee;
            color: $primary-color;
        }
    }
}
.form-btns{
    .btn{
        display: block;
        width: 100%;
        border-radius: 0;
        padding: 0.75rem 1.25rem;
        & + .btn{
            margin-top: 1em;
        }
    }
}