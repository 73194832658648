//Override Defaults here
$theme-primary-color: #5577d9;

$primary-color: $theme-primary-color;

$font-color: #0e1a35;
$grey4b: #4b4b4b; 
$white: #fff;
$off-white: #f0f0f0;
$dark-color: #404144;

$grey-bg: #e9ebf2;
$greyeee: #eee;
$modal-bg: #f6f7fa;

$input-border-radius: 0;
$red: #ed0000;