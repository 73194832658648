.page-sidebar{
    display: flex;
    flex-wrap: wrap;
    margin: 20px 0 20px 20px;
    @media(max-width:767px){
        display: block;
        margin: 15px 0;
    }
    h2{
        font-weight: 400;
        @include fontSize(24px);
        color: $primary-color;
        margin-bottom: 25px;
    }
    .ad-content{
        box-shadow: 0 0 35px rgba(53,152,219,0.10);
        background: $white;
        margin-right: 20px;
        padding: 20px;
        flex: 1;
        height: calc( 100vh - 130px);
        overflow: auto;
        @media(max-width:767px){
            height: auto;
            padding: 15px;
            margin-right: 0;
            max-width: 100%;
        }
        iframe{
            border: 1px solid $greyeee;
            margin: 0 auto;
            display: block;
            max-width: 100%;
            overflow-x: auto;
        }
    }
    .right-sidebar{
        box-shadow: 0 0 35px rgba(53,152,219,0.10);
        background: $white;
        padding: 20px;
        height: calc( 100vh - 130px);
        width: 320px;
        max-width: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        margin-left: auto;
        @media(min-width: 768px) and (max-width: 1099px){
            width: 280px;
        }
        @media(max-width:767px){
            height: auto;
            width: 100%;
            margin-left: 0;
            padding: 15px;
            margin-top: 15px;
        }
        
        .creator-name{
            color: $grey4b;
            @include fontSize(20px);
            letter-spacing: 1px;
            font-weight: 500;
        }
        label{
            display: block;
            @include fontSize(15px);
        }
        .accordian{
            padding: 12px 0;
            border-bottom: 1px solid $greyeee;
            label{
                cursor: pointer;
                &:last-child{
                    margin-bottom: 0;
                }
            }
            .arrow{
                float: right;
                width: 12px;
                svg{
                    max-width: 12px;
                    fill: $grey4b;
                }
            }
            &.show{
                .arrow{
                    @include transform(rotate(-180deg));
                }
            }
            .sidebar-input{
                display: flex;
                input{
                    flex: 1;
                    margin-right: 15px;
                    padding: 0;
                }
                .btn{
                    padding: 6px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    svg{
                        width: 10px;
                        height: 10px;
                        fill: $primary-color;
                    }
                    & + .btn{
                        margin-left: 10px;
                    }
                }
            }
            .sidebar-list{
                .sidebar-list-states{
                    padding: 10px;
                    background: rgba(246, 247, 250, 0.55);
                    outline: 1px solid rgba($color: $primary-color, $alpha: 0.1);
                    margin-bottom: 10px;
                    position: relative;
                    h3{
                        @include fontSize(15px);
                        color: $grey4b;
                    }
                    .close-state{
                        border: 0 none;
                        background: transparent;
                        float: right;
                        cursor: pointer;
                        padding: 3px 0;
                        svg{
                            width: 10px;
                            height: 10px;
                            fill: $grey4b;
                        }
                    }
                    label{
                        text-transform: capitalize;
                    }
                }
            }
        }
        .btn{
            border-radius: 0;
            padding: 0.4rem 0.6rem;
            @include fontSize(15px);
            &.btn-primary{
                background: rgba(0,0,0,0);
                color: $primary-color;
                border: 2px solid $primary-color;
                &:hover{
                    background: $primary-color;
                    color: $white;
                }
            }
            &.btn-default{
                background: rgba(0,0,0,0);
                color: $primary-color;
                border: 2px solid $greyeee;
                &:hover{
                    background: $greyeee;
                    color: $primary-color;
                }
            }
            &.btn-switch{
                margin: 5px auto;
                display: block;
                @include fontSize(14px);
            }
            &.btn-delete{
                color: $red;
                line-height: 1;
                padding: 3px;
                svg{
                    fill: $red !important;
                    width: 12px;
                    height: 12px;
                }
            }
        }
        .sidebar-list{
            list-style: none;
            li{
                padding: 7px 0;
                label{
                    @include fontSize(14px);
                    .arrow{
                        float: right;
                        width: 10px;
                        @include transform(none !important);
                        svg{
                            max-width: 10px;
                            fill: $grey4b;
                        }
                    }
                }
                ul{
                    padding: 0;
                    li{
                        list-style: none;
                        padding: 7px;
                        background: $modal-bg;
                        margin-bottom: 10px;
                    }
                }
                .values-list{
                    li{
                        @include fontSize(14px);
                        color: $primary-color;
                        text-transform: capitalize;
                        padding: 7px;
                        background: $modal-bg;
                        margin-bottom: 10px;
                        border-top: none;
                        .object-icon{
                            color: $primary-color;
                            max-width: 12px;
                            margin-right: 7px;
                            line-height: 1;
                            svg{
                                width: 12px;
                                height: 12px;
                                fill: $primary-color;
                            }
                        }
                        .btn-delete{
                            float: right;
                            border: 0 none;
                            padding: 0;
                        }
                    }
                }
            }
        }
    }
}
.check-box{
    .btn{
        padding: 5px !important;
        color: $primary-color !important;
        line-height: 1;
        svg{
            width: 9px;
            height: 9px;
        }
    }
    .arrow-right{
        float: right;
        width: 10px;
        @include transform(rotate(-90deg));
        color: $primary-color;
        svg{
            max-width: 10px;
            fill: $grey4b;
        }
    }
}
.existing-texts{
    list-style: none;
    .text-span{
        text-transform: capitalize;
    }
    .btn{
        padding: 5px !important;
        color: $primary-color !important;
        line-height: 1;
        margin-left: 7px;
        border-radius: 0;
        svg{
            width: 9px;
            height: 9px;
            fill: $primary-color !important;
        } 
    }
}
#imageSection{
    margin-bottom: 1.25rem;
}
.text-errors{
    @include fontSize(14px);
    color: $red;
    padding: 5px 0;
}
.bin-object-container{
    margin-top: 20px;
    background: rgba($color: $primary-color, $alpha: 0.1);
    &.show{
        .arrow{
            @include transform(rotate(-180deg));
        }
    }
    h3{
        margin: 0;
        padding: 15px;
        color: $font-color;
        font-weight: 500;
        border-bottom: 1px solid rgba(53,152,219,0.1);
        &:last-child{
            border-bottom: 0 none;
        }
    }
    > .form-group{
        padding: 15px;
        margin-bottom: 0;
        .row{
            label{
                margin-bottom: 0;
            }
        }
        .form-group{
            margin-bottom: 0;
        }
    }
    .btn.obj-action-btn{
        padding: 4px 7px;
        margin-bottom: 5px;
    }
}
#root{
    > div > .loader {
        background: rgba(0,0,0,0);
    }
}