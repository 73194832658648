/* Bootstrap Files */
@import "scss/bootstrap/bootstrap-grid";
@import "scss/bootstrap/reboot";
@import "scss/bootstrap/mixins";
@import "scss/bootstrap/type";
@import "scss/bootstrap/breadcrumb";
@import "scss/bootstrap/forms";
@import "scss/bootstrap/custom-forms";

/* THEME FONTS */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400&display=swap');

/* THEME STYLING */
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body{
    // background: #f4f6f8;
    background: #fff;
    .App{
        background: #f4f6f8;
    }
} 
html,
body,
input,
button {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
}
img{
    max-width: 100%;
}
.mr-t-15{
    margin-top: 15px;
}
.mr-b-15{
    margin-bottom: 15px;
}
/* TOOLTIP */
body .__react_component_tooltip{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    max-width: 500px;
    padding: 8px 15px;
    @media(max-width: 767px){
        max-width: 300px;
        padding: 8px;
    }
    img{
        max-width: 100%;
        height: auto;
        & + img{
            margin-top: 5px;
        }
    }
    .tooltip-text{
        display: block;
        width: 100%;
        margin: 5px 0;
    }
}
/* THEME STYLING */
@import "scss/theme/variables";
@import "scss/theme/mixin-theme";
@import "scss/theme/dashboard-layout";
@import "scss/theme/header";
@import "scss/theme/sidenav";
@import "scss/theme/login";
@import "scss/theme/listing";
@import "scss/theme/modal";
@import "scss/theme/button";
@import "scss/theme/form";
@import "scss/theme/right-sidebar";
@import "scss/theme/toggle-arrow";
@import "scss/theme/react-modal";