.login-page{
    min-height: 100vh;
    header{
        background: $theme-primary-color;
        padding: 30px 20px;
        h1{
            color: $white;
            text-align: center;
            margin: 0;
            @include fontSize(26px);
        }
    }
    .container-fluid{
        padding-top: 35px;
        padding-bottom: 35px;
        .row{
            max-width: 85%;
            margin: 0 auto;
            min-height: calc( 100vh - 165px );
            @include align-items(center);
            @include justify-content(space-between);
        }
    }
    .login{
        padding: 50px;
        box-shadow: 2px 0 14px rgba(0, 37, 61, 0.14);
        border-radius: 10px;
        @media(min-width: 768px){
            max-width: 35%;
        }
        @media(max-width: 991px){
            padding: 35px;
        }
        @media(max-width: 767px){
            padding: 25px 15px;
        }
        h2 {
            text-align: center;
            color: $primary-color;
            margin-bottom: 1em;
            @include fontSize(36px);
        }
        .auth-message {
            text-align: center;
            color: orange;
        }
        .input-group {
            display: flex;
            flex-direction: column;
            margin-bottom: 2.5em;
            input {
                outline: none;
                border: 0 none;
                border-bottom: 1px solid #8492af;
                color: #1f1f1f;
                border-radius: 0;
                padding: 0.5em 1.5em;
                font-weight: 500;
                @include fontSize(18px);
                @include input-placeholder{
                    color: rgba(31,31,31,0.5);
                }
                &.input-error {
                  border: 1px solid #ed0000;
                }
            }
            small {
                font-size: 0.6em;
                margin: 0.6em 0 0 1.7em;
                color: #ed0000;
            }
            &.email-field input{
                background-image: url(../../images/email-icon.png);
                background-repeat: no-repeat;
                background-position: left center;
                background-size: 20px auto;
                padding-left: 1.85em;
            }
            &.password-field input{
                background-image: url(../../images/padlock-icon.png);
                background-repeat: no-repeat;
                background-position: left center;
                background-size: 17px auto;
                padding-left: 1.85em;
            }
        }
        .form-btns .btn + .btn{
            margin-left: 0;
        }
    }
    .side-img{
        @media(min-width: 768px){
            padding-left: 5%;
        }
    }   
}